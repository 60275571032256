import { createInertiaApp } from '@inertiajs/vue3';
import * as Sentry from '@sentry/vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ru.js';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import { usePassThrough } from 'primevue/passthrough';
import Tailwind from 'primevue/passthrough/tailwind';
import ToastService from 'primevue/toastservice';
import { createApp, h } from 'vue';
import { ZiggyVue } from 'ziggy-js';
import AppLayout from './Admin/Layouts/AppLayout.vue';
import Theme from './Admin/Theme.js';
import './bootstrap';

const pinia = createPinia();
dayjs.locale('ru');
const theme = usePassThrough(
  Tailwind,
  Theme,
  {
    mergeSections: true,
    mergeProps: false,
  },
);

const permissionMixin = {
  methods: {
    userCan(permission) {
      if (this.userHasRole('SuperAdmin')) {
        return true;
      }

      return this.$page.props.auth.user.permissions.includes(permission);
    },
    userCanAny(permissions) {
      if (this.userHasRole('SuperAdmin')) {
        return true;
      }

      return permissions.some((permission) => this.userCan(permission));
    },
    userCanAll(permissions) {
      if (this.userHasRole('SuperAdmin')) {
        return true;
      }

      return permissions.every((permission) => this.userCan(permission));
    },
    userHasRole(role) {
      return this.$page.props.auth.user.roles.includes(role);
    },
    userHasAnyRole(roles) {
      return roles.some((role) => this.userHasRole(role));
    },
  },
};

createInertiaApp({
  title: (title) => `${title} - ZAKAZBILETOV.KZ`,
  resolve: async (name) => {
    const page = await resolvePageComponent(`./Admin/Views/${name}.vue`, import.meta.glob('./Admin/Views/**/*.vue'));
    page.default.layout = page.default.layout || AppLayout;
    return page;
  },
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) });

    Sentry.init({
      app,
      dsn: 'https://4b68bde54a6a5c8f4b5f287a4534be12@o244733.ingest.us.sentry.io/4507220735754240',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.browserProfilingIntegration(),
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      // Profiling
      profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
    });

    app.mixin(permissionMixin)
      .use(plugin)
      .use(pinia)
      .use(ZiggyVue)
      .use(PrimeVue, {
        ripple: true,
        unstyled: true,
        pt: theme,
      })
      .use(ConfirmationService)
      .use(ToastService)
      .use(DialogService)
      .mount(el);

    return app;
  },
  progress: {
    color: '#FFFFFF',
  },
});
