export const TRANSITIONS = {
  toggleable: {
    enterFromClass: 'max-h-0',
    enterActiveClass: 'overflow-hidden transition-all duration-500 ease-in-out',
    enterToClass: 'max-h-40	',
    leaveFromClass: 'max-h-40',
    leaveActiveClass: 'overflow-hidden transition-all duration-500 ease-in',
    leaveToClass: 'max-h-0',
  },
  overlay: {
    enterFromClass: 'opacity-0 scale-75',
    enterActiveClass: 'transition-transform transition-opacity duration-150 ease-in',
    leaveActiveClass: 'transition-opacity duration-150 ease-linear',
    leaveToClass: 'opacity-0',
  },
};

export default {
  card: {
    body: {
      class: 'py-4 px-6',
    }, // Padding.
    content: {
      class: 'py-0', // Vertical padding.
    },
  },
  tabview: {
    tabpanel: {
      content: {
        class: ['bg-white pt-3 pb-2 border-0 text-gray-700 rounded-bl-2xl rounded-br-2xl', 'dark:bg-gray-900 dark:border-blue-900/40 dark:text-white/80'], // Background, padding, border, and text styles.
      },
    },
  },
  fileupload: {
    root: ({ props }) => ({
      class: {
        'flex': props.mode === 'basic',
      },
    }),
    input: {
      class: 'hidden',
    },
    buttonbar: {
      class: ['flex flex-wrap', 'bg-gray-50 dark:bg-gray-800 px-3 p-2 border border-solid border-gray-300 text-gray-700 rounded-tr-lg rounded-tl-lg gap-2 border-b-0'],
    },
    chooseButton: {
      class: ['flex items-center text-white bg-blue-500 border border-blue-500 px-3 p-2 rounded-md text-sm cursor-pointer', 'overflow-hidden relative'],
    },
    chooseIcon: {
      class: 'mr-2 inline-block',
    },
    chooseButtonLabel: {
      class: 'flex-1 font-bold',
    },
    uploadbutton: {
      icon: {
        class: 'mr-2',
      },
    },
    cancelbutton: {
      class: ['flex items-center text-white bg-blue-500 border border-blue-500 px-3 p-2 rounded-md text-sm', 'overflow-hidden relative'],

      icon: {
        class: 'mr-2',
      },
    },
    content: {
      class: ['relative', 'bg-white dark:bg-gray-900 p-4 border border-gray-300 dark:border-blue-900/40 text-gray-700 dark:text-white/80 rounded-b-lg'],
    },
    file: {
      class: ['flex items-center flex-wrap', 'p-4 border border-gray-300 dark:border-blue-900/40 rounded gap-2 mb-2', 'last:mb-0'],
    },
    thumbnail: {
      class: 'shrink-0',
    },
    fileName: {
      class: 'mb-2',
    },
    fileSize: {
      class: 'mr-2',
    },
    uploadicon: {
      class: 'mr-2',
    },
  },
  button: {
    root: ({ props, context }) => ({
      class: [
        'inline-flex items-center cursor-pointer overflow-hidden relative select-none text-center align-bottom',
        'transition duration-200 ease-in-out',
        'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(157,193,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(147,197,253,0.7),0_1px_2px_0_rgba(0,0,0,0)]', // Primary button focus
        {
          'text-white bg-blue-500 border border-blue-500 hover:bg-blue-600 hover:border-blue-600': !props.link && props.severity === null && !props.text && !props.outlined && !props.plain,
          'text-blue-600 bg-transparent border-transparent': props.link,
        },
        {
          'text-white bg-gray-500 border border-gray-500 hover:bg-gray-600 hover:border-gray-600': props.severity === 'secondary' && !props.text && !props.outlined && !props.plain,
          'text-white bg-green-500 border border-green-500 hover:bg-green-600 hover:border-green-600': props.severity === 'success' && !props.text && !props.outlined && !props.plain,
          'text-white bg-blue-500 border border-blue-500 hover:bg-blue-600 hover:border-blue-600': props.severity === 'info' && !props.text && !props.outlined && !props.plain,
          'text-white bg-orange-500 border border-orange-500 hover:bg-orange-600 hover:border-orange-600': props.severity === 'warning' && !props.text && !props.outlined && !props.plain,
          'text-white bg-purple-500 border border-purple-500 hover:bg-purple-600 hover:border-purple-600': props.severity === 'help' && !props.text && !props.outlined && !props.plain,
          'text-white bg-red-500 border border-red-500 hover:bg-red-600 hover:border-red-600': props.severity === 'danger' && !props.text && !props.outlined && !props.plain,
        },
        { 'shadow-lg': props.raised },
        { 'rounded-md': !props.rounded, 'rounded-full': props.rounded },
        {
          'bg-transparent border-transparent': props.text && !props.plain,
          'text-blue-500 hover:bg-blue-300/20': props.text && (props.severity === null || props.severity === 'info') && !props.plain,
          'text-gray-500 hover:bg-gray-300/20': props.text && props.severity === 'secondary' && !props.plain,
          'text-green-500 hover:bg-green-300/20': props.text && props.severity === 'success' && !props.plain,
          'text-orange-500 hover:bg-orange-300/20': props.text && props.severity === 'warning' && !props.plain,
          'text-purple-500 hover:bg-purple-300/20': props.text && props.severity === 'help' && !props.plain,
          'text-red-500 hover:bg-red-300/20': props.text && props.severity === 'danger' && !props.plain,
        },
        { 'shadow-lg': props.raised && props.text },
        {
          'text-gray-500 hover:bg-gray-300/20': props.plain && props.text,
          'text-gray-500 border border-gray-500 hover:bg-gray-300/20': props.plain && props.outlined,
          'text-white bg-gray-500 border border-gray-500 hover:bg-gray-600 hover:border-gray-600': props.plain && !props.outlined && !props.text,
        },
        {
          'bg-transparent border': props.outlined && !props.plain,
          'text-blue-500 border border-blue-500 hover:bg-blue-300/20': props.outlined && (props.severity === null || props.severity === 'info') && !props.plain,
          'text-gray-500 border border-gray-500 hover:bg-gray-300/20': props.outlined && props.severity === 'secondary' && !props.plain,
          'text-green-500 border border-green-500 hover:bg-green-300/20': props.outlined && props.severity === 'success' && !props.plain,
          'text-orange-500 border border-orange-500 hover:bg-orange-300/20': props.outlined && props.severity === 'warning' && !props.plain,
          'text-purple-500 border border-purple-500 hover:bg-purple-300/20': props.outlined && props.severity === 'help' && !props.plain,
          'text-red-500 border border-red-500 hover:bg-red-300/20': props.outlined && props.severity === 'danger' && !props.plain,
        },
        {
          'px-4 py-2 text-base': props.size === null,
          'text-xs py-2 px-3': props.size === 'small',
          'text-xl py-3 px-4': props.size === 'large',
        },
        { 'opacity-60 pointer-events-none cursor-default': context.disabled },
      ],
    }),
  },
  splitbutton: {
    root: ({ props }) => ({
      class: ['inline-flex relative', 'rounded-md', { 'shadow-lg': props.raised }],
    }),
    button: {
      root: ({ props, parent, context }) => {
        return {
          class: [
            'rounded-r-none border-r-0',
            { 'rounded-l-full': parent.props.rounded },
            {
              'text-white dark:text-gray-900 bg-blue-500 dark:bg-blue-400 border border-blue-500 dark:border-blue-400 hover:bg-blue-600 dark:hover:bg-blue-500 hover:border-blue-600 dark:hover:border-blue-500 focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(157,193,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(147,197,253,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                !parent.props.link && parent.props.severity === null && !parent.props.text && !parent.props.outlined && !parent.props.plain,
              'text-blue-600 bg-transparent border-transparent focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(157,193,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(147,197,253,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
              parent.props.link,
            },
            {
              'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(176,185,198,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(203,213,225,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                props.severity === 'secondary',
              'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(136,234,172,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(134,239,172,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                props.severity === 'success',
              'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(157,193,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(147,197,253,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                props.severity === 'info',
              'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(250,207,133,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(252,211,77,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                props.severity === 'warning',
              'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(212,170,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(216,180,254,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                props.severity === 'help',
              'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(247,162,162,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(252,165,165,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                props.severity === 'danger',
            },
            {
              'text-white dark:text-gray-900 bg-gray-500 dark:bg-gray-400 border border-gray-500 dark:border-gray-400 hover:bg-gray-600 dark:hover:bg-gray-500 hover:border-gray-600 dark:hover:border-gray-500':
                props.severity === 'secondary' && !props.text && !props.outlined && !props.plain,
              'text-white dark:text-gray-900 bg-green-500 dark:bg-green-400 border border-green-500 dark:border-green-400 hover:bg-green-600 dark:hover:bg-green-500 hover:border-green-600 dark:hover:border-green-500':
                props.severity === 'success' && !props.text && !props.outlined && !props.plain,
              'text-white dark:text-gray-900 dark:bg-blue-400 bg-blue-500 dark:bg-blue-400 border border-blue-500 dark:border-blue-400 hover:bg-blue-600 hover:border-blue-600 dark:hover:bg-blue-500 dark:hover:border-blue-500':
                props.severity === 'info' && !props.text && !props.outlined && !props.plain,
              'text-white dark:text-gray-900 bg-orange-500 dark:bg-orange-400 border border-orange-500 dark:border-orange-400 hover:bg-orange-600 dark:hover:bg-orange-500 hover:border-orange-600 dark:hover:border-orange-500':
                props.severity === 'warning' && !props.text && !props.outlined && !props.plain,
              'text-white dark:text-gray-900 bg-purple-500 dark:bg-purple-400 border border-purple-500 dark:border-purple-400 hover:bg-purple-600 dark:hover:bg-purple-500 hover:border-purple-600 dark:hover:border-purple-500':
                props.severity === 'help' && !props.text && !props.outlined && !props.plain,
              'text-white dark:text-gray-900 bg-red-500 dark:bg-red-400 border border-red-500 dark:border-red-400 hover:bg-red-600 dark:hover:bg-red-500 hover:border-red-600 dark:hover:border-red-500':
                props.severity === 'danger' && !props.text && !props.outlined && !props.plain,
            },
            { 'shadow-lg': props.raised },
            { 'rounded-md': !props.rounded, 'rounded-full': props.rounded },
            {
              'bg-transparent border-transparent': props.text && !props.plain,
              'text-blue-500 dark:text-blue-400 hover:bg-blue-300/20': props.text && (props.severity === null || props.severity === 'info') && !props.plain,
              'text-gray-500 dark:text-gray-400 hover:bg-gray-300/20': props.text && props.severity === 'secondary' && !props.plain,
              'text-green-500 dark:text-green-400 hover:bg-green-300/20': props.text && props.severity === 'success' && !props.plain,
              'text-orange-500 dark:text-orange-400 hover:bg-orange-300/20': props.text && props.severity === 'warning' && !props.plain,
              'text-purple-500 dark:text-purple-400 hover:bg-purple-300/20': props.text && props.severity === 'help' && !props.plain,
              'text-red-500 dark:text-red-400 hover:bg-red-300/20': props.text && props.severity === 'danger' && !props.plain,
            },
            { 'shadow-lg': props.raised && props.text },
            {
              'text-gray-500 hover:bg-gray-300/20': props.plain && props.text,
              'text-gray-500 border border-gray-500 hover:bg-gray-300/20': props.plain && props.outlined,
              'text-white bg-gray-500 border border-gray-500 hover:bg-gray-600 hover:border-gray-600': props.plain && !props.outlined && !props.text,
            },
            {
              'bg-transparent border': props.outlined && !props.plain,
              'text-blue-500 dark:text-blue-400 border border-blue-500 dark:border-blue-400 hover:bg-blue-300/20': props.outlined && (props.severity === null || props.severity === 'info') && !props.plain,
              'text-gray-500 dark:text-gray-400 border border-gray-500 dark:border-gray-400 hover:bg-gray-300/20': props.outlined && props.severity === 'secondary' && !props.plain,
              'text-green-500 dark:text-green-400 border border-green-500 dark:border-green-400 hover:bg-green-300/20': props.outlined && props.severity === 'success' && !props.plain,
              'text-orange-500 dark:text-orange-400 border border-orange-500 dark:border-orange-400 hover:bg-orange-300/20': props.outlined && props.severity === 'warning' && !props.plain,
              'text-purple-500 dark:text-purple-400 border border-purple-500 dark:border-purple-400 hover:bg-purple-300/20': props.outlined && props.severity === 'help' && !props.plain,
              'text-red-500 dark:text-red-400 border border-red-500 dark:border-red-400 hover:bg-red-300/20': props.outlined && props.severity === 'danger' && !props.plain,
            },
            {
              'px-4 py-2 text-base': props.size === null,
              'text-xs py-2 px-3': props.size === 'small',
              'text-xl py-3 px-4': props.size === 'large',
            },
            { 'opacity-60 pointer-events-none cursor-default': context.disabled },
          ],
        };
      },
      icon: () => {
        return {
          class: 'mr-2',
        };
      },
    },
  },
  inputtext: {
    root: ({ props, context }) => ({
      class: [
        'w-full',
        'm-0',
        'font-sans text-gray-600 bg-white border border-gray-300 transition-colors duration-200 appearance-none rounded-lg',
        {
          'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]': !context.disabled,
          'opacity-60 select-none pointer-events-none cursor-default': context.disabled,
        },
        {
          'text-lg px-4 py-4': props.size === 'large',
          'text-xs px-2 py-2': props.size === 'small',
          'p-2 text-base': props.size == null,
        },
      ],
    }),
  },
  inputnumber: {
    root: ({ props }) => ({
      class: [
        'w-full flex',
        { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled },
      ],
    }),
    input: {
      root: ({ parent }) => {
        return {
          class: [
            'm-0 w-full',
            'font-sans text-gray-600 bg-white dark:bg-gray-900 border border-gray-300 transition-colors duration-200 appearance-none rounded-lg',
            'p-2 text-base',
            {
              'rounded-tr-none rounded-br-none': parent.props.showButtons && parent.props.buttonLayout === 'stacked',
            },
            {
              'order-2': parent.props.buttonLayout === 'horizontal',
            },
          ],
        };
      },
    },
  },
  password: {
    root: ({ props }) => ({
      class: [
        'inline-flex relative w-full',
        {
          'opacity-60 select-none pointer-events-none cursor-default': props.disabled,
        },
      ],
    }),
    panel: {
      class: 'p-3 bg-white text-gray-700 shadow-md rounded-md',
    },
    meter: {
      class: 'mb-2 bg-gray-300 h-1',
    },
  },
  dropdown: {
    root: ({ props }) => ({
      class: [
        'cursor-pointer inline-flex relative select-none',
        'bg-white border border-gray-300 transition-colors duration-200 ease-in-out rounded-md',
        'w-full',
        'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]',
        { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled },
      ],
    }),
    input: ({ props }) => ({
      class: [
        'cursor-pointer flex flex-auto overflow-hidden overflow-ellipsis whitespace-nowrap relative',
        'bg-transparent border-0 text-gray-800',
        'p-2 transition duration-200 bg-transparent rounded appearance-none font-sans text-base',
        'focus:outline-none focus:shadow-none',
        { 'pr-7': props.showClear },
      ],
    }),
    list: {
      class: 'py-2 list-none m-0',
    },
    item: ({ context }) => ({
      class: [
        'cursor-pointer font-normal overflow-hidden relative whitespace-nowrap',
        'm-0 p-2 border-0  transition-shadow duration-200 rounded-none',
        'dark:text-white/80 dark:hover:bg-gray-800',
        'hover:text-gray-700 hover:bg-gray-200',
        {
          'text-gray-700': !context.focused && !context.selected,
          'bg-gray-300 text-gray-700': context.focused && !context.selected,
          'bg-blue-400 text-blue-700': context.focused && context.selected,
          'bg-blue-50 text-blue-700': !context.focused && context.selected,
        },
      ],
    }),
    itemgroup: {
      class: ['m-0 p-2 text-gray-800 bg-white font-bold', 'dark:bg-gray-900 dark:text-white/80', 'cursor-auto'],
    },
    header: {
      class: ['p-2 border-b border-gray-300 text-gray-700 bg-gray-100 mt-0 rounded-tl-lg rounded-tr-lg', 'dark:bg-gray-800 dark:text-white/80 dark:border-blue-900/40'],
    },
  },
  multiselect: {
    root: ({ props }) => ({
      class: [
        'w-full',
        'inline-flex cursor-pointer select-none',
        'bg-white dark:bg-gray-900 border border-gray-300 dark:border-blue-900/40  transition-colors duration-200 ease-in-out rounded-md',
        { 'opacity-60 select-none pointer-events-none cursor-default': props?.disabled },
      ],
    }),
    label: ({ props }) => ({
      class: [
        'block overflow-hidden whitespace-nowrap cursor-pointer overflow-ellipsis',
        'text-gray-700 dark:text-white/80',
        'p-3 transition duration-200',
        {
          '!p-3': props.display !== 'chip' && (props?.modelValue ?? true),
          '!py-1.5 px-3': props.display === 'chip' && props?.modelValue !== null,
        },
      ],
    }),
    header: {
      class: ['p-2 border-b border-gray-300 dark:border-blue-900/40 text-gray-700 dark:text-white/80 bg-gray-100 dark:bg-gray-800 rounded-t-lg', 'flex items-center justify-between'],
    },
  },
  textarea: {
    root: ({ context }) => ({
      class: [
        'm-0 w-full',
        'font-sans text-base text-gray-600 dark:text-white/80 bg-white dark:bg-gray-900 p-2 border border-gray-300 dark:border-blue-900/40 transition-colors duration-200 appearance-none rounded-lg',
        'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
        { 'opacity-60 select-none pointer-events-none cursor-default': context.disabled },
      ],
    }),
  },
  autocomplete: {
    root: ({ props }) => ({
      class: [
        'relative inline-flex',
        {
          'opacity-60 select-none pointer-events-none cursor-default': props.disabled,
        },
        'w-full',
      ],
    }),
    container: {
      class: [
        'm-0 list-none cursor-text overflow-hidden flex items-center flex-wrap w-full',
        'px-3 py-2 gap-2',
        'font-sans text-base text-gray-700 dark:text-white/80 bg-white dark:bg-gray-900 border border-gray-300 dark:border-blue-900/40  transition duration-200 ease-in-out appearance-none rounded-md',
        'focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] hover:border-blue-500 focus:outline-none dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
      ],
    },
    inputtoken: {
      class: ['py-0.375rem px-0', 'flex-1 inline-flex'],
    },
    input: ({ props }) => ({
      class: [
        'w-full m-0',
        'transition-colors duration-200 appearance-none rounded-lg',
        { 'rounded-tr-none rounded-br-none': props.dropdown },
        {
          'font-sans text-base text-gray-700 dark:text-white/80 bg-white dark:bg-gray-900 p-2 border border-gray-300 dark:border-blue-900/40 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)] hover:border-blue-500 focus:outline-none':
            !props.multiple,
          'font-sans text-base text-gray-700 dark:text-white/80 border-0 outline-none bg-transparent m-0 p-0 shadow-none rounded-none w-full': props.multiple,
        },
      ],
    }),
    token: {
      class: ['py-1 px-2 mr-2 bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-white/80 rounded-full', 'cursor-default inline-flex items-center'],
    },
    loadingicon: {
      class: 'text-sky-800 absolute',
    },
    dropdownbutton: {
      root: {
        class: 'rounded-md border border-gray-300 border-l-0 rounded-tl-none rounded-bl-none px-2',
      },
      label: 'hidden',
    },
    panel: {
      class: ['bg-white text-gray-700 border-0 rounded-md shadow-lg', 'max-h-[200px] overflow-auto', 'bg-white text-gray-700 border-0 rounded-md shadow-lg', 'dark:bg-gray-900 dark:text-white/80'],
    },
    list: {
      class: 'py-3 list-none m-0',
    },
    item: ({ context }) => ({
      class: [
        'cursor-pointer font-normal overflow-hidden relative whitespace-nowrap',
        'm-0 p-3 border-0  transition-shadow duration-200 rounded-none',
        'dark:text-white/80 dark:hover:bg-gray-800',
        'hover:text-gray-700 hover:bg-gray-200',
        {
          'text-gray-700': !context.focused && !context.selected,
          'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-gray-800/90': context.focused && !context.selected,
          'bg-blue-500 text-blue-700 dark:bg-blue-400 dark:text-white/80': context.focused && context.selected,
          'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:text-white/80': !context.focused && context.selected,
        },
      ],
    }),
    itemgroup: {
      class: ['m-0 p-3 text-gray-800 bg-white font-bold', 'dark:bg-gray-900 dark:text-white/80', 'cursor-auto'],
    },
  },
  panelmenu: {
    root: {
      class: 'w-full',
    },
    panel: {
      class: '!my-0',
    },
    header: {
      class: [
        'outline-none',
        'focus:outline-none focus:outline-offset-0 focus:shadow-none focus:bg-header', // Focus
      ],
    },
    headercontent: {
      class: [
        // 'py-2 px-4',
        '!border-none bg-transparent text-white rounded-none transition-shadow duration-200',
        'hover:bg-header hover:text-white',
      ],
    },
    headeraction: {
      class: ['flex items-center select-none cursor-pointer relative no-underline', 'text-white p-5 font-bold'],
    },
    submenuicon: {
      class: 'mr-2',
    },
    headericon: {
      class: 'mr-2',
    },
    menucontent: {
      class: '',
    },
    menu: {
      class: ['outline-none', 'm-0 p-0 list-none'],
    },
    content: ({ context }) => ({
      class: [
        '!bg-transparent text-white',
        'hover:bg-header hover:text-white', // Hover
        {
          '!bg-transparent text-white': context.focused,
        },
      ],
    }),
    action: {
      class: ['text-white py-2 px-5 select-none', 'flex items-center cursor-pointer no-underline relative overflow-hidden'],
    },
  },
  datatable: {
    header: ({ props }) => ({
      class: [
        'bg-slate-50 text-slate-700 border-white font-bold p-3',
        props.showGridlines ? 'border-x border-t border-b-0' : 'border-y border-x-0',
      ],
    }),
    column: {
      headercell: ({ context }) => ({
        class: [
          'text-left border border-solid border-white font-bold',
          'transition duration-200',
          context?.size === 'small' ? 'p-2' : context?.size === 'large' ? 'p-5' : 'p-3', // Size
          context.sorted ? 'bg-blue-50 text-blue-700' : 'bg-slate-50 text-slate-700', // Sort
          {
            // 'sticky z-[1]': props.frozen || props.frozen === '', // Frozen Columns
            'border-x border-y': context?.showGridlines,
            'overflow-hidden space-nowrap border-y relative bg-clip-padding': context.resizable, // Resizable
          },
        ],
      }),
      columnfilter: {
        class: 'inline-flex items-center ml-auto w-full',
      },
      filterinput: {
        class: 'w-full',
      },
      bodycell: ({ context }) => ({
        class: [
          'text-left border border-solid border-white',
          context?.size === 'small' ? 'p-2' : context?.size === 'large' ? 'p-5' : 'p-3', // Size
          {
            // 'sticky bg-inherit': context.frozen || context.frozen === '', // Frozen Columns
            'border-x border-y': context?.showGridlines,
          },
        ],
      }),
      footercell: ({ context }) => ({
        class: [
          'text-left border-0 border-b border-solid border-white font-bold',
          'bg-slate-50 text-slate-700',
          'transition duration-200',
          context?.size === 'small' ? 'p-2' : context?.size === 'large' ? 'p-5' : 'p-4', // Size
          {
            'border-x border-y': context?.showGridlines,
          },
        ],
      }),
    },
    bodyrow: ({ context, props }) => ({
      class: [
        props?.rowClass || context.selected || context.stripedRows ? '' : 'bg-white',
        context.selected ? 'bg-blue-100 text-blue-700 dark:bg-blue-300' : 'text-gray-600',
        context.stripedRows ? (context.index % 2 === 0 ? 'bg-white text-gray-600 dark:bg-gray-900' : 'bg-gray-50 text-gray-600') : '',
        'transition duration-200',
        'focus:outline focus:outline-[0.15rem] focus:outline-blue-200 focus:outline-offset-[-0.15rem]', // Focus
        {
          'cursor-pointer': context.selectable,
          'hover:bg-gray-300/20 hover:text-gray-600': context.selectable && !context.selected, // Hover
        },
      ],
    }),
  },
  dialog: {
    title: {
      class: 'text-xl font-medium',
    },
  },
  toolbar: {
    root: {
      class: ['flex items-center justify-between flex-wrap', 'bg-gray-100 dark:bg-gray-800 border border-gray-300 dark:border-blue-900/40  p-5 rounded-md gap-2'],
    },
    start: {
      class: 'flex items-center',
    },
    center: {
      class: 'flex items-center',
    },
    end: {
      class: 'flex items-center',
    },
  },
  togglebutton: {
    root: ({ props, context }) => ({
      class: [
        'inline-flex cursor-pointer select-none items-center align-bottom text-center overflow-hidden relative',
        'px-4 py-2 rounded-md text-base',
        'border transition duration-200 ease-in-out',
        {
          'outline-none outline-offset-0 shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]': context.focused,
        },
        {
          'bg-green-500/60 border-green-600/60 text-white hover:bg-green-600/60': !props.modelValue,
          'bg-green-500 border-green-500 text-white hover:bg-green-600 hover:border-green-600': props.modelValue,
        },
        { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled },
      ],
    }),
    label: {
      class: 'font-bold text-center w-full',
    },
    icon: ({ props }) => ({
      class: [
        ' mr-2',
        {
          'text-white/60': !props.modelValue,
          'text-white': props.modelValue,
        },
      ],
    }),
  },
  colorpicker: {
    root: ({ props }) => ({
      class: [
        'block',
        {
          'opacity-60 select-none pointer-events-none cursor-default': props.disabled,
        },
      ],
    }),
    input: {
      class: [
        'm-0',
        'font-sans text-base text-gray-600 p-3 border border-gray-300 dark:border-blue-900/40 transition-colors duration-200 rounded-lg cursor-pointer',
        'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
        'w-full h-10',
      ],
    },
  },
  fieldset: {
    root: {
      class: [
        'border border-gray-300 bg-white text-gray-700 rounded-md block inline-size-min', // Borders, background, text color, spacing, and inline size.
      ],
    },
    legend: ({ props }) => ({
      class: [
        'text-gray-700 font-bold outline-none ml-4',
        {
          'p-0 transition-none hover:text-gray-900': props.toggleable,
          'p-5': !props.toggleable,
        },
      ],
    }),
    toggler: ({ props }) => ({
      class: [
        'flex items-center justify-center',
        {
          'px-4 py-2 text-gray-700 outline-none transition-none cursor-pointer overflow-hidden relative select-none hover:text-gray-900 focus:focus:shadow-[inset_0_0_0_0.2rem_rgba(191,219,254,1)] dark:text-white/80 dark:hover:text-white/80 dark:hover:bg-gray-800/60 dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]':
          props.toggleable,
        },
      ],
    }),
    togglerIcon: {
      class: 'mr-2 inline-block', // Margin and display style.
    },
    legendTitle: {
      class: 'flex items-center justify-center leading-none', // alignments, and leading style.
    },
    content: {
      class: 'p-4', // Padding.
    },
    transition: TRANSITIONS.toggleable,
  },
  message: {
    root: ({ props }) => {
      return {
        class: [
          'my-4 rounded-md',
          {
            'bg-blue-100 border-solid border-0 border-l-4 border-blue-500 text-blue-700': props.severity === 'info',
            'bg-green-100 border-solid border-0 border-l-4 border-green-500 text-green-700': props.severity === 'success',
            'bg-orange-100 border-solid border-0 border-l-4 border-orange-500 text-orange-700': props.severity === 'warn',
            'bg-red-100 border-solid border-0 border-l-4 border-red-500 text-red-700': props.severity === 'error',
          },
        ],
      };
    },
    wrapper: {
      class: 'flex items-center py-5 px-7',
    },
    icon: {
      class: ['w-6 h-6', 'text-lg mr-2'],
    },
    text: {
      class: 'text-base font-normal',
    },
    button: {
      class: ['w-8 h-8 rounded-full bg-transparent transition duration-200 ease-in-out', 'ml-auto overflow-hidden relative', 'flex items-center justify-center', 'hover:bg-white/30'],
    },
    transition: {
      enterFromClass: 'opacity-0',
      enterActiveClass: 'transition-opacity duration-300',
      leaveFromClass: 'max-h-40',
      leaveActiveClass: 'overflow-hidden transition-all duration-300 ease-in',
      leaveToClass: 'max-h-0 opacity-0 !m-0',
    },
  },
};
